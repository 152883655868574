import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { fetchNeighbors } from '~/components/neighbors/neighbors-api';
import {
  DENVER_LAT,
  DENVER_LNG,
  DENVER_NEIGHBORS,
} from '~/components/static/home/neighbors-section/denver-neighbors';
import { captureException } from '~/utils/exception-tracking';

const MIN_NEIGHBORS = 20;
const BREAKPOINTS = [3, 10];
const INITIAL_RADIUS = BREAKPOINTS[0];

/**
 * Fetch lawns near the given lat/lng.
 * Starts at a small radius and increases it until the number of lawns
 * is above a threshold.
 */
export const useNeighbors = (
  { lat, lng, radiusOverride },
  { enabled = true, usePlaceholderData = false } = {}
) => {
  const queryClient = useQueryClient();
  const [radius, setRadius] = useState(radiusOverride || INITIAL_RADIUS);

  useEffect(() => {
    if (usePlaceholderData) {
      queryClient.setQueryData(
        [
          'neighbors',
          { lat: DENVER_LAT, lng: DENVER_LNG, radius: BREAKPOINTS[0] },
        ],
        DENVER_NEIGHBORS
      );
    }
  }, [usePlaceholderData, queryClient]);

  // Reset `radius` when `lat`, `lng`, or `radiusOverride` changes
  useEffect(() => {
    setRadius(radiusOverride || INITIAL_RADIUS);
  }, [lat, lng, radiusOverride]);

  const { data, ...rest } = useQuery(
    ['neighbors', { lat, lng, radius }],
    () => fetchNeighbors({ latitude: lat, longitude: lng, radius }),
    {
      enabled: Boolean(lat && lng && radius && enabled),
      onSuccess(res) {
        if (res.length < MIN_NEIGHBORS) {
          setRadius(nextRadius(radius));
          return null;
        }

        return res;
      },
      onError(err) {
        captureException(new Error('Neighbors API Error'), {
          extras: {
            error: err,
            errorString: JSON.stringify(err),
          },
        });
      },
    }
  );

  return {
    neighbors: data?.length > MIN_NEIGHBORS ? data : null,
    radius,
    ...rest,
  };
};

function nextRadius(currentRadius) {
  const currentIndex = BREAKPOINTS.indexOf(currentRadius);
  return BREAKPOINTS[currentIndex + 1] || null;
}
