export const DENVER_NEIGHBORS = [
  {
    latitude: 39.714591144799996,
    longitude: -104.98675817559999,
  },
  {
    latitude: 39.719640461599994,
    longitude: -104.9739273568,
  },
  {
    latitude: 39.729276954,
    longitude: -104.946023988,
  },
  {
    latitude: 39.719428216,
    longitude: -104.97223190199999,
  },
  {
    latitude: 39.719768170399995,
    longitude: -104.9998109788,
  },
  {
    latitude: 39.718630036,
    longitude: -104.990556408,
  },
  {
    latitude: 39.7232116896,
    longitude: -104.966916726,
  },
  {
    latitude: 39.714746463599994,
    longitude: -104.98437518159999,
  },
  {
    latitude: 39.7213741688,
    longitude: -104.99862746359999,
  },
  {
    latitude: 39.7172569656,
    longitude: -104.99234894959999,
  },
  {
    latitude: 39.723498733199996,
    longitude: -104.9698492092,
  },
  {
    latitude: 39.7273420452,
    longitude: -104.9577529168,
  },
  {
    latitude: 39.7382830348,
    longitude: -104.93585356839999,
  },
  {
    latitude: 39.7274021848,
    longitude: -104.95232358599999,
  },
  {
    latitude: 39.715436311999994,
    longitude: -104.9767225932,
  },
  {
    latitude: 39.73670585119999,
    longitude: -104.960227074,
  },
  {
    latitude: 39.726865847999996,
    longitude: -104.9628845616,
  },
  {
    latitude: 39.7334025908,
    longitude: -104.94442049959999,
  },
  {
    latitude: 39.7387086304,
    longitude: -104.9312495256,
  },
  {
    latitude: 39.7383608448,
    longitude: -104.9482230492,
  },
  {
    latitude: 39.736951128399994,
    longitude: -104.94932263,
  },
  {
    latitude: 39.738004424799996,
    longitude: -104.9295316816,
  },
  {
    latitude: 39.7341561932,
    longitude: -104.94771914159999,
  },
  {
    latitude: 39.7185937916,
    longitude: -104.99466326999999,
  },
  {
    latitude: 39.728571443199996,
    longitude: -104.99930345679999,
  },
  {
    latitude: 39.7260665636,
    longitude: -104.9791970516,
  },
  {
    latitude: 39.7236893928,
    longitude: -104.9514761096,
  },
  {
    latitude: 39.7277631228,
    longitude: -104.96929941879999,
  },
  {
    latitude: 39.729273640799995,
    longitude: -104.9575961924,
  },
  {
    latitude: 39.714958508399995,
    longitude: -104.9860707368,
  },
  {
    latitude: 39.7239066584,
    longitude: -104.9745689128,
  },
  {
    latitude: 39.772100264799995,
    longitude: -105.0275362744,
  },
  {
    latitude: 39.7463375244,
    longitude: -104.93232611479999,
  },
  {
    latitude: 39.732092772399994,
    longitude: -104.94716935119999,
  },
  {
    latitude: 39.7711198588,
    longitude: -104.9995427464,
  },
  {
    latitude: 39.7496723104,
    longitude: -104.93131819919999,
  },
  {
    latitude: 39.7596453436,
    longitude: -104.94148841799999,
  },
  {
    latitude: 39.7388564192,
    longitude: -104.9466310064,
  },
  {
    latitude: 39.782092876,
    longitude: -105.0176813116,
  },
  {
    latitude: 39.7574344352,
    longitude: -104.9230726484,
  },
  {
    latitude: 39.738308837599995,
    longitude: -104.93181066119999,
  },
  {
    latitude: 39.7885153636,
    longitude: -105.0067268684,
  },
  {
    latitude: 39.7154628176,
    longitude: -104.9879496224,
  },
  {
    latitude: 39.719062960799995,
    longitude: -104.99395294,
  },
  {
    latitude: 39.75992807,
    longitude: -104.9414426356,
  },
  {
    latitude: 39.729620221599994,
    longitude: -104.9504909848,
  },
  {
    latitude: 39.7235655996,
    longitude: -104.9789679388,
  },
  {
    latitude: 39.7702841292,
    longitude: -104.9492683136,
  },
  {
    latitude: 39.7673700192,
    longitude: -105.02625286119999,
  },
  {
    latitude: 39.7665134064,
    longitude: -104.9533086104,
  },
  {
    latitude: 39.7518727772,
    longitude: -104.9359451332,
  },
  {
    latitude: 39.75774386799999,
    longitude: -104.9638009124,
  },
  {
    latitude: 39.763274904,
    longitude: -104.95509312,
  },
  {
    latitude: 39.7336350168,
    longitude: -104.9550038644,
  },
  {
    latitude: 39.7421938156,
    longitude: -104.9266549204,
  },
  {
    latitude: 39.76076078759999,
    longitude: -104.9353954432,
  },
  {
    latitude: 39.7778867184,
    longitude: -105.01834254599999,
  },
  {
    latitude: 39.741496738399995,
    longitude: -104.9301157084,
  },
  {
    latitude: 39.77703492479999,
    longitude: -104.9995770832,
  },
  {
    latitude: 39.76871748759999,
    longitude: -105.0277196048,
  },
  {
    latitude: 39.7689644716,
    longitude: -105.01117268079999,
  },
  {
    latitude: 39.760631572799994,
    longitude: -105.02730706119999,
  },
  {
    latitude: 39.7729647088,
    longitude: -105.01140189399999,
  },
  {
    latitude: 39.7609313672,
    longitude: -104.966916726,
  },
  {
    latitude: 39.755935664,
    longitude: -104.973671136,
  },
  {
    latitude: 39.7898130336,
    longitude: -104.9897826252,
  },
  {
    latitude: 39.7580828184,
    longitude: -105.030149084,
  },
  {
    latitude: 39.7611648976,
    longitude: -105.02955320999999,
  },
  {
    latitude: 39.779848936,
    longitude: -105.01163110719999,
  },
  {
    latitude: 39.783937224,
    longitude: -105.01717710279999,
  },
  {
    latitude: 39.765026382,
    longitude: -104.961922328,
  },
  {
    latitude: 39.7532269724,
    longitude: -105.024419256,
  },
  {
    latitude: 39.7441347484,
    longitude: -104.93012715399999,
  },
  {
    latitude: 39.7592807912,
    longitude: -105.0306533932,
  },
  {
    latitude: 39.7607330772,
    longitude: -104.95518709439999,
  },
  {
    latitude: 39.762677524000004,
    longitude: -104.95880691599999,
  },
  {
    latitude: 39.757177612,
    longitude: -104.934232008,
  },
  {
    latitude: 39.7516391464,
    longitude: -105.02776548759999,
  },
  {
    latitude: 39.765925464,
    longitude: -104.962489588,
  },
  {
    latitude: 39.756216181599996,
    longitude: -104.9308601744,
  },
  {
    latitude: 39.7517747868,
    longitude: -104.9231184308,
  },
  {
    latitude: 39.735630265999994,
    longitude: -104.979242834,
  },
  {
    latitude: 39.7677538484,
    longitude: -105.0275362744,
  },
  {
    latitude: 39.7884317304,
    longitude: -105.00466445160001,
  },
  {
    latitude: 39.7385040152,
    longitude: -104.9543624088,
  },
  {
    latitude: 39.773339803199995,
    longitude: -105.013051968,
  },
  {
    latitude: 39.7677324632,
    longitude: -104.94492440719999,
  },
  {
    latitude: 39.7582516912,
    longitude: -104.9370446136,
  },
  {
    latitude: 39.7644610296,
    longitude: -104.9352121128,
  },
  {
    latitude: 39.72927464479999,
    longitude: -104.996290252,
  },
  {
    latitude: 39.7609093796,
    longitude: -104.9408928452,
  },
  {
    latitude: 39.742551139199996,
    longitude: -104.936174246,
  },
  {
    latitude: 39.7636886524,
    longitude: -105.01735059399999,
  },
  {
    latitude: 39.762469194,
    longitude: -104.9314098644,
  },
  {
    latitude: 39.7806321564,
    longitude: -105.010485242,
  },
  {
    latitude: 39.747060303999994,
    longitude: -104.930084484,
  },
  {
    latitude: 39.7139500908,
    longitude: -104.98501673759999,
  },
  {
    latitude: 39.73892790399999,
    longitude: -104.9613725376,
  },
  {
    latitude: 39.7656948452,
    longitude: -105.0106456812,
  },
  {
    latitude: 39.7523937528,
    longitude: -104.9364949236,
  },
  {
    latitude: 39.7602741488,
    longitude: -105.02941566199999,
  },
  {
    latitude: 39.78007925359999,
    longitude: -105.0194689336,
  },
  {
    latitude: 39.7161899144,
    longitude: -104.9854291808,
  },
  {
    latitude: 39.740377077599994,
    longitude: -104.95395006599999,
  },
  {
    latitude: 39.7205709688,
    longitude: -104.9879037396,
  },
  {
    latitude: 39.7604418168,
    longitude: -104.95138434399999,
  },
  {
    latitude: 39.7583835164,
    longitude: -104.96013540879999,
  },
  {
    latitude: 39.755017003999995,
    longitude: -104.937594404,
  },
  {
    latitude: 39.7625167836,
    longitude: -104.9461156532,
  },
  {
    latitude: 39.763972683999995,
    longitude: -105.01835609999999,
  },
  {
    latitude: 39.74645439,
    longitude: -104.9260043284,
  },
  {
    latitude: 39.764936524,
    longitude: -104.9513957896,
  },
  {
    latitude: 39.766451760799995,
    longitude: -105.0177156484,
  },
  {
    latitude: 39.7457702644,
    longitude: -104.9340897412,
  },
  {
    latitude: 39.7644911496,
    longitude: -105.0127310896,
  },
  {
    latitude: 39.755359468399995,
    longitude: -104.93136408199999,
  },
  {
    latitude: 39.745263445199996,
    longitude: -104.93750273879999,
  },
  {
    latitude: 39.75824084799999,
    longitude: -105.030149084,
  },
  {
    latitude: 39.7622728116,
    longitude: -104.958211042,
  },
  {
    latitude: 39.750221598799996,
    longitude: -104.97621848479999,
  },
  {
    latitude: 39.7627383664,
    longitude: -104.96068529959999,
  },
  {
    latitude: 39.755020015999996,
    longitude: -104.96622748,
  },
  {
    latitude: 39.75606397519999,
    longitude: -104.9271953736,
  },
  {
    latitude: 39.768818992,
    longitude: -104.957463564,
  },
  {
    latitude: 39.75854485919999,
    longitude: -104.9625180012,
  },
  {
    latitude: 39.7611375888,
    longitude: -104.9700096484,
  },
  {
    latitude: 39.762613970800004,
    longitude: -104.9480856016,
  },
  {
    latitude: 39.760102163599996,
    longitude: -104.98254217879999,
  },
  {
    latitude: 39.7647645388,
    longitude: -104.9588304096,
  },
  {
    latitude: 39.7659292792,
    longitude: -105.0238463736,
  },
  {
    latitude: 39.7514371416,
    longitude: -105.0267814672,
  },
  {
    latitude: 39.738000207999995,
    longitude: -104.9358993508,
  },
  {
    latitude: 39.7587283904,
    longitude: -104.9656337144,
  },
  {
    latitude: 39.749209867999994,
    longitude: -104.93484565279999,
  },
  {
    latitude: 39.7511894548,
    longitude: -104.93015004520001,
  },
  {
    latitude: 39.7644888404,
    longitude: -104.9498380836,
  },
  {
    latitude: 39.7499263224,
    longitude: -104.9313411908,
  },
  {
    latitude: 39.7437494132,
    longitude: -104.93608258079999,
  },
  {
    latitude: 39.7351214388,
    longitude: -104.978601278,
  },
  {
    latitude: 39.7510558224,
    longitude: -104.9674665164,
  },
  {
    latitude: 39.7433740176,
    longitude: -104.93351716000001,
  },
  {
    latitude: 39.7441412744,
    longitude: -104.9358076856,
  },
  {
    latitude: 39.7674408012,
    longitude: -104.97181955919999,
  },
  {
    latitude: 39.775505431199996,
    longitude: -105.0152061504,
  },
  {
    latitude: 39.767765796,
    longitude: -105.00645769599998,
  },
  {
    latitude: 39.7652581052,
    longitude: -105.022494086,
  },
  {
    latitude: 39.7176485256,
    longitude: -104.99688602559999,
  },
  {
    latitude: 39.773378457199996,
    longitude: -105.01186021999999,
  },
  {
    latitude: 39.735298444,
    longitude: -105.00175602799999,
  },
  {
    latitude: 39.7749166856,
    longitude: -105.00828517679999,
  },
  {
    latitude: 39.719444079199995,
    longitude: -104.9939873772,
  },
  {
    latitude: 39.750788658,
    longitude: -105.02237952959999,
  },
  {
    latitude: 39.722728364,
    longitude: -104.98100334799999,
  },
  {
    latitude: 39.7652088088,
    longitude: -105.0287052316,
  },
  {
    latitude: 39.7805535432,
    longitude: -105.0110810156,
  },
  {
    latitude: 39.780203448399995,
    longitude: -105.0188271768,
  },
  {
    latitude: 39.7799856808,
    longitude: -105.0159853548,
  },
  {
    latitude: 39.7818854496,
    longitude: -105.0143123896,
  },
  {
    latitude: 39.7784284768,
    longitude: -105.01011858119999,
  },
  {
    latitude: 39.778642830799996,
    longitude: -105.0165812288,
  },
  {
    latitude: 39.78776146,
    longitude: -105.01483948959999,
  },
  {
    latitude: 39.7800202184,
    longitude: -105.02231075559999,
  },
  {
    latitude: 39.788740560799994,
    longitude: -105.0113102288,
  },
  {
    latitude: 39.766496137599994,
    longitude: -105.02868224000001,
  },
  {
    latitude: 39.7744048464,
    longitude: -105.0106685724,
  },
  {
    latitude: 39.7578456736,
    longitude: -105.030149084,
  },
  {
    latitude: 39.761935969599996,
    longitude: -104.9586692676,
  },
  {
    latitude: 39.76518350799999,
    longitude: -105.028935248,
  },
  {
    latitude: 39.7701546132,
    longitude: -104.9638009124,
  },
  {
    latitude: 39.748976739199996,
    longitude: -104.92485916599999,
  },
  {
    latitude: 39.7584057048,
    longitude: -105.03129514999999,
  },
  {
    latitude: 39.7524353184,
    longitude: -104.97621848479999,
  },
  {
    latitude: 39.7682223148,
    longitude: -104.9472151336,
  },
  {
    latitude: 39.751799686,
    longitude: -105.02684873519999,
  },
  {
    latitude: 39.758939832799996,
    longitude: -104.92485916599999,
  },
  {
    latitude: 39.77662529279999,
    longitude: -105.019445942,
  },
  {
    latitude: 39.7619402868,
    longitude: -104.9574321388,
  },
  {
    latitude: 39.7531554876,
    longitude: -104.93370039,
  },
  {
    latitude: 39.764649480399996,
    longitude: -104.9662369176,
  },
  {
    latitude: 39.766455877199995,
    longitude: -105.027490492,
  },
  {
    latitude: 39.7564121624,
    longitude: -104.9594939532,
  },
  {
    latitude: 39.769391272,
    longitude: -105.0127310896,
  },
  {
    latitude: 39.753236811600004,
    longitude: -105.0277883788,
  },
  {
    latitude: 39.748886881199994,
    longitude: -105.02675706999999,
  },
  {
    latitude: 39.758867845999994,
    longitude: -104.929027774,
  },
  {
    latitude: 39.7738864812,
    longitude: -105.0125018764,
  },
  {
    latitude: 39.7540329836,
    longitude: -104.976251416,
  },
  {
    latitude: 39.75917256,
    longitude: -104.93484565279999,
  },
  {
    latitude: 39.7693856496,
    longitude: -105.0198127032,
  },
  {
    latitude: 39.76530198,
    longitude: -105.01167688959998,
  },
  {
    latitude: 39.7579585232,
    longitude: -105.0100418756,
  },
  {
    latitude: 39.752417648,
    longitude: -104.9254546384,
  },
  {
    latitude: 39.7546504436,
    longitude: -104.94038893759999,
  },
  {
    latitude: 39.7518893432,
    longitude: -104.93310481719999,
  },
  {
    latitude: 39.760673339200004,
    longitude: -104.9625637836,
  },
  {
    latitude: 39.7589626236,
    longitude: -104.9824161768,
  },
  {
    latitude: 39.769878914799996,
    longitude: -104.95326282799999,
  },
  {
    latitude: 39.772582385599996,
    longitude: -105.0163978984,
  },
  {
    latitude: 39.768781342,
    longitude: -105.0105928708,
  },
  {
    latitude: 39.7521554032,
    longitude: -104.9718081136,
  },
  {
    latitude: 39.7645827144,
    longitude: -104.9643965856,
  },
  {
    latitude: 39.7485057628,
    longitude: -104.9650610328,
  },
  {
    latitude: 39.754708976799996,
    longitude: -104.97305678839999,
  },
  {
    latitude: 39.761342404800004,
    longitude: -104.9649922588,
  },
  {
    latitude: 39.7658801836,
    longitude: -104.9527130376,
  },
  {
    latitude: 39.7779780824,
    longitude: -105.017727094,
  },
  {
    latitude: 39.754032280800004,
    longitude: -104.93425008000001,
  },
  {
    latitude: 39.756425214400004,
    longitude: -104.9557827676,
  },
  {
    latitude: 39.7623548384,
    longitude: -104.9581677696,
  },
  {
    latitude: 39.7658987576,
    longitude: -105.026023648,
  },
  {
    latitude: 39.75655714,
    longitude: -104.92778833599999,
  },
  {
    latitude: 39.754638998000004,
    longitude: -104.97058233,
  },
  {
    latitude: 39.759896243200004,
    longitude: -104.97058233,
  },
  {
    latitude: 39.769999997199996,
    longitude: -105.0024187044,
  },
  {
    latitude: 39.7703045104,
    longitude: -104.9668708432,
  },
  {
    latitude: 39.7677692096,
    longitude: -104.93530377799999,
  },
  {
    latitude: 39.7570552244,
    longitude: -105.030149084,
  },
  {
    latitude: 39.744155129599996,
    longitude: -104.94625310079999,
  },
  {
    latitude: 39.75632521599999,
    longitude: -104.93425008000001,
  },
  {
    latitude: 39.758924471600004,
    longitude: -104.93191377199999,
  },
  {
    latitude: 39.74989841119999,
    longitude: -104.92838641879999,
  },
  {
    latitude: 39.757730514799995,
    longitude: -105.02143988599998,
  },
  {
    latitude: 39.783399682399995,
    longitude: -105.0105513052,
  },
  {
    latitude: 39.769322096399996,
    longitude: -104.9466653432,
  },
  {
    latitude: 39.7765732856,
    longitude: -105.0125018764,
  },
  {
    latitude: 39.7690339484,
    longitude: -104.9502160896,
  },
  {
    latitude: 39.769430428,
    longitude: -105.01812518,
  },
  {
    latitude: 39.778420243999996,
    longitude: -105.0200648076,
  },
  {
    latitude: 39.778484801199994,
    longitude: -105.0165812288,
  },
  {
    latitude: 39.778591928,
    longitude: -105.0223566384,
  },
  {
    latitude: 39.7756309312,
    longitude: -105.01436299119999,
  },
  {
    latitude: 39.7893306116,
    longitude: -105.00900705279999,
  },
  {
    latitude: 39.7394827144,
    longitude: -104.93122663439999,
  },
  {
    latitude: 39.7364578632,
    longitude: -104.9358993508,
  },
  {
    latitude: 39.730861768000004,
    longitude: -104.964075908,
  },
  {
    latitude: 39.722007491999996,
    longitude: -104.99973196399999,
  },
  {
    latitude: 39.7244380756,
    longitude: -104.94890346,
  },
  {
    latitude: 39.737157350000004,
    longitude: -104.95957417279999,
  },
  {
    latitude: 39.724083462799996,
    longitude: -104.9681080724,
  },
  {
    latitude: 39.729112298,
    longitude: -104.961739098,
  },
  {
    latitude: 39.7309649792,
    longitude: -104.9617848804,
  },
  {
    latitude: 39.747766818799995,
    longitude: -104.9242865848,
  },
  {
    latitude: 39.723580258,
    longitude: -104.9785554956,
  },
  {
    latitude: 39.741826251199996,
    longitude: -104.93928945719999,
  },
  {
    latitude: 39.726758419999996,
    longitude: -104.94442049959999,
  },
  {
    latitude: 39.74190315759999,
    longitude: -104.9474442464,
  },
  {
    latitude: 39.7162645116,
    longitude: -104.9866665104,
  },
  {
    latitude: 39.734250167599996,
    longitude: -104.9465508872,
  },
  {
    latitude: 39.7500153772,
    longitude: -105.02299829479999,
  },
  {
    latitude: 39.7387242928,
    longitude: -104.93067694439999,
  },
  {
    latitude: 39.74336829479999,
    longitude: -104.9528504852,
  },
  {
    latitude: 39.744148202,
    longitude: -104.94570331039999,
  },
  {
    latitude: 39.7226371004,
    longitude: -104.99624436919999,
  },
  {
    latitude: 39.743304440399996,
    longitude: -104.9374602696,
  },
  {
    latitude: 39.744133644,
    longitude: -104.9295316816,
  },
  {
    latitude: 39.728664714800004,
    longitude: -104.9969432536,
  },
  {
    latitude: 39.73569251399999,
    longitude: -104.96259822079999,
  },
  {
    latitude: 39.7427898904,
    longitude: -104.9352579956,
  },
  {
    latitude: 39.71778527039999,
    longitude: -104.9974244708,
  },
  {
    latitude: 39.7435261236,
    longitude: -104.9335629424,
  },
  {
    latitude: 39.7354894048,
    longitude: -104.9562408928,
  },
  {
    latitude: 39.7735331736,
    longitude: -105.003793582,
  },
  {
    latitude: 39.768679034399995,
    longitude: -105.0295073272,
  },
  {
    latitude: 39.7734274524,
    longitude: -105.006392436,
  },
  {
    latitude: 39.7806977176,
    longitude: -105.01717710279999,
  },
  {
    latitude: 39.7689004164,
    longitude: -105.01914805519999,
  },
  {
    latitude: 39.7795494428,
    longitude: -105.0136019592,
  },
  {
    latitude: 39.77682016919999,
    longitude: -105.021928332,
  },
  {
    latitude: 39.7689255164,
    longitude: -105.01986932879998,
  },
  {
    latitude: 39.7749552392,
    longitude: -105.00709352919999,
  },
  {
    latitude: 39.7822724916,
    longitude: -105.00943104199999,
  },
  {
    latitude: 39.7697844384,
    longitude: -105.0142436156,
  },
  {
    latitude: 39.7778857144,
    longitude: -105.01603123759999,
  },
  {
    latitude: 39.7576987884,
    longitude: -104.9533086104,
  },
  {
    latitude: 39.764056016,
    longitude: -104.950863268,
  },
  {
    latitude: 39.7620719112,
    longitude: -104.9302188192,
  },
  {
    latitude: 39.7437746136,
    longitude: -104.94508484640001,
  },
  {
    latitude: 39.7673971272,
    longitude: -104.95748946719999,
  },
  {
    latitude: 39.763510342,
    longitude: -104.96503804119999,
  },
  {
    latitude: 39.732595174,
    longitude: -104.94936851279999,
  },
  {
    latitude: 39.7387335296,
    longitude: -104.97278179279999,
  },
  {
    latitude: 39.75336753239999,
    longitude: -104.92605021119999,
  },
  {
    latitude: 39.756664066,
    longitude: -104.92774506359999,
  },
  {
    latitude: 39.738757725999996,
    longitude: -104.95724890879998,
  },
  {
    latitude: 39.749593396,
    longitude: -105.027661172,
  },
  {
    latitude: 39.7685963048,
    longitude: -104.9994847152,
  },
  {
    latitude: 39.767731559599994,
    longitude: -105.00883516799999,
  },
  {
    latitude: 39.7597728516,
    longitude: -105.02991997119999,
  },
  {
    latitude: 39.7564817396,
    longitude: -104.92430947599999,
  },
  {
    latitude: 39.7585268876,
    longitude: -105.022368084,
  },
  {
    latitude: 39.7312215012,
    longitude: -105.0012270568,
  },
  {
    latitude: 39.764881002799996,
    longitude: -105.01786464199999,
  },
  {
    latitude: 39.743332953999996,
    longitude: -104.9344334104,
  },
  {
    latitude: 39.7632921728,
    longitude: -105.0064633184,
  },
  {
    latitude: 39.7382102448,
    longitude: -104.96698539959999,
  },
  {
    latitude: 39.7297176096,
    longitude: -104.9438707092,
  },
  {
    latitude: 39.7570284176,
    longitude: -104.9403431552,
  },
  {
    latitude: 39.745936828,
    longitude: -105.02600658,
  },
  {
    latitude: 39.761611175599995,
    longitude: -105.007394428,
  },
  {
    latitude: 39.7549117848,
    longitude: -105.02991997119999,
  },
  {
    latitude: 39.7669438212,
    longitude: -104.9545457392,
  },
  {
    latitude: 39.7695733976,
    longitude: -105.0047561168,
  },
  {
    latitude: 39.7519803056,
    longitude: -105.02877390519998,
  },
  {
    latitude: 39.7660406228,
    longitude: -104.9656337144,
  },
  {
    latitude: 39.7507601444,
    longitude: -104.927241156,
  },
  {
    latitude: 39.755272923599996,
    longitude: -104.94090188119999,
  },
  {
    latitude: 39.77934302039999,
    longitude: -105.0171885484,
  },
  {
    latitude: 39.7690377636,
    longitude: -104.9319595544,
  },
  {
    latitude: 39.7537872044,
    longitude: -104.9656337144,
  },
  {
    latitude: 39.757022494,
    longitude: -105.03129514999999,
  },
  {
    latitude: 39.767775534799995,
    longitude: -105.02826969639999,
  },
  {
    latitude: 39.750791168,
    longitude: -104.97119226,
  },
  {
    latitude: 39.7695892608,
    longitude: -105.0268028524,
  },
  {
    latitude: 39.788251612799996,
    longitude: -104.9514760092,
  },
  {
    latitude: 39.764487234,
    longitude: -105.0199977404,
  },
  {
    latitude: 39.761416499999996,
    longitude: -104.968179256,
  },
  {
    latitude: 39.7732831776,
    longitude: -105.0188730596,
  },
  {
    latitude: 39.78197229559999,
    longitude: -105.00631442519999,
  },
  {
    latitude: 39.7589066004,
    longitude: -104.98674662959999,
  },
  {
    latitude: 39.755083569199996,
    longitude: -104.9687037456,
  },
  {
    latitude: 39.7665653132,
    longitude: -104.9718653416,
  },
  {
    latitude: 39.7584377324,
    longitude: -104.94918518239999,
  },
  {
    latitude: 39.764706106,
    longitude: -104.9589441628,
  },
  {
    latitude: 39.7668864928,
    longitude: -105.018987616,
  },
  {
    latitude: 39.7613756372,
    longitude: -104.9231642132,
  },
  {
    latitude: 39.766944624400004,
    longitude: -104.94671122599999,
  },
  {
    latitude: 39.750554625599996,
    longitude: -105.0246026868,
  },
  {
    latitude: 39.7689061392,
    longitude: -104.96630960719999,
  },
  {
    latitude: 39.76509314799999,
    longitude: -105.0168562244,
  },
  {
    latitude: 39.761611276,
    longitude: -104.9527130376,
  },
  {
    latitude: 39.7718091048,
    longitude: -105.006681086,
  },
  {
    latitude: 39.7532985576,
    longitude: -104.92838641879999,
  },
  {
    latitude: 39.750639564,
    longitude: -105.02200312999999,
  },
  {
    latitude: 39.760790807199996,
    longitude: -104.98188917719999,
  },
  {
    latitude: 39.7517850276,
    longitude: -104.9254546384,
  },
  {
    latitude: 39.766867818399994,
    longitude: -105.0300574188,
  },
  {
    latitude: 39.7517637428,
    longitude: -104.93255512719999,
  },
  {
    latitude: 39.76826568759999,
    longitude: -105.024556804,
  },
  {
    latitude: 39.755379749199996,
    longitude: -104.938144094,
  },
  {
    latitude: 39.7514224832,
    longitude: -104.9619682108,
  },
  {
    latitude: 39.767356164,
    longitude: -104.96494647639999,
  },
  {
    latitude: 39.7561256208,
    longitude: -105.0312033844,
  },
  {
    latitude: 39.7545386984,
    longitude: -104.9779598224,
  },
  {
    latitude: 39.7609559652,
    longitude: -104.92485916599999,
  },
  {
    latitude: 39.76197311759999,
    longitude: -104.9526672552,
  },
  {
    latitude: 39.7543957288,
    longitude: -104.9706282128,
  },
  {
    latitude: 39.75304314,
    longitude: -104.9249049484,
  },
  {
    latitude: 39.7581435604,
    longitude: -104.9277679548,
  },
  {
    latitude: 39.759518538399995,
    longitude: -104.93537245159999,
  },
  {
    latitude: 39.7827623432,
    longitude: -105.0110772004,
  },
  {
    latitude: 39.757041068,
    longitude: -104.96132675519999,
  },
  {
    latitude: 39.76737775,
    longitude: -104.95395006599999,
  },
  {
    latitude: 39.75399272319999,
    longitude: -104.9791053864,
  },
  {
    latitude: 39.766368529199994,
    longitude: -105.0122194512,
  },
  {
    latitude: 39.7621401832,
    longitude: -104.96132675519999,
  },
  {
    latitude: 39.785633482,
    longitude: -105.0136019592,
  },
  {
    latitude: 39.767345822799996,
    longitude: -105.0264361916,
  },
  {
    latitude: 39.770728298799995,
    longitude: -104.9709374448,
  },
  {
    latitude: 39.757577404799996,
    longitude: -104.9599521788,
  },
  {
    latitude: 39.7658841996,
    longitude: -105.0024644868,
  },
  {
    latitude: 39.769542173199994,
    longitude: -105.00109137999999,
  },
  {
    latitude: 39.774798313999995,
    longitude: -105.0037134628,
  },
  {
    latitude: 39.764559220799995,
    longitude: -104.9582569248,
  },
  {
    latitude: 39.7619473148,
    longitude: -104.9266456836,
  },
  {
    latitude: 39.77740299119999,
    longitude: -105.0125018764,
  },
  {
    latitude: 39.7835600212,
    longitude: -105.0095210004,
  },
  {
    latitude: 39.764824577999995,
    longitude: -105.0242818084,
  },
  {
    latitude: 39.782223396,
    longitude: -104.95976884839999,
  },
  {
    latitude: 39.772539816,
    longitude: -105.000656584,
  },
  {
    latitude: 39.7508879536,
    longitude: -104.96934520119999,
  },
  {
    latitude: 39.7552226232,
    longitude: -104.9242635932,
  },
  {
    latitude: 39.758041754800004,
    longitude: -104.9314098644,
  },
  {
    latitude: 39.7574820248,
    longitude: -104.97301090559999,
  },
];

export const DENVER_LAT = 39.756268;
export const DENVER_LNG = -104.977201;
