import React from 'react';
import cx from 'classnames';

import Tooltip from '~/components/tooltip/tooltip';

import styles from '~/components/neighbors/neighbors-widget.module.scss';

export function FloatingCard({
  neighborCount,
  city,
  state,
  postalCode,
  variant,
}) {
  const isHomePageVariant = variant === 'homepage';

  const addressContent = postalCode ? (
    postalCode
  ) : (
    <>
      {city}
      {state && `, ${state}`}
    </>
  );

  return (
    <div
      className={cx(styles.floatingCard, {
        [styles.homepage]: isHomePageVariant,
      })}
    >
      <p>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <span className={styles.floatingCardCircle}></span>
          <span className={styles.floatingCardNeighborCount}>
            {neighborCount || '-'}
          </span>
        </span>
        Sunday yards {isHomePageVariant ? 'in' : 'near'}
        <span className={styles.floatingCardLocation}>{addressContent}</span>
      </p>

      <p className={styles.floatingCardFinePrint}>
        We take privacy seriously
        <Tooltip content="Your privacy is important to us. That's why we only show approximate locations." />
      </p>
    </div>
  );
}

export function NoNeighborsCard() {
  return (
    <div className={cx(styles.floatingCard, styles.noNeighborsCard)}>
      <h3>Be a pioneer!</h3>
      <h4>Bring the Sunday revolution to your neighborhood</h4>
      <p className={styles.floatingCardFinePrint}>
        We take privacy seriously
        <Tooltip content="Your privacy is important to us. That's why we only show approximate locations." />
      </p>
    </div>
  );
}
