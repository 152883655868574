import { createHttpClient } from '~/utils/http-client';
import { getSavedSession } from '~/features/session/session';

const neighborsHttp = createHttpClient({
  baseUrl: window.env.NEIGHBORHOODS_URL,
  requestTransformers: [
    async (request) => {
      const session = await getSavedSession();

      if (!session || !session.token) {
        return request;
      }

      return {
        ...request,
        headers: {
          Authorization: `Bearer ${session.token}`,
          ...request.headers,
        },
      };
    },
  ],
});

export const fetchNeighbors = async ({ latitude, longitude, radius }) => {
  const params = new URLSearchParams({
    latitude,
    longitude,
    search_distance_in_miles: radius,
  });

  const res = await neighborsHttp.get(`/list_neighbors/?${params}`);

  return res.neighborsData;
};
